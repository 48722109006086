<template>
  <b-overlay
    :show="$apollo.loading || mutationLoading"
  >
    <b-card>
      <div class="d-flex  align-items-center justify-content-between">
        <div class="d-flex align-items-baseline ">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-secondary"
            @click="$router.back()"
          >
            <feather-icon
              class="mr-25"
              icon="ChevronLeftIcon"
            />
          </b-button>
          <h3 class="ml-1">
            All Responses
          </h3>
        </div>
        <b-badge
          variant="primary"
          class="mr-50"
        >
          {{ $route.params.mname }}
        </b-badge>
      </div>
      <hr>
      <!-- search input -->
      <div class="custom-search d-flex justify-content-start">
        <b-form-group
          class="form-inline"
          label="Search"
          label-size="sm"
        >
          <b-form-input
            v-model="searchTerm"
            class="d-inline-block mr-1"
            placeholder="Search response"
            type="text"
          />
        </b-form-group>
      </div>
      <!-- table -->
      <vue-good-table
        :columns="columns"
        :pagination-options="{
          enabled: true,
          perPage:pageLength
        }"
        :rows="rows"
        :search-options="{
          enabled: true,
          externalQuery: searchTerm }"
      >
        <template
          slot="table-row"
          slot-scope="props"
        >

          <!-- Column: Action -->
          <span v-if="props.column.field === 'action'">
            <span>
              <b-button
                :to="{name: 'forms-responses-response',
                      params: {fid:$route.params.fid,
                               aid: props.row.organization_id_id,},
                      query: {readonly: 'true'}}"
                class="mr-50"
                size="sm"
                variant="outline-primary"
              > View Exercise </b-button>
              <b-button
                size="sm"
                variant="outline-primary"
                @click="selectedRow=props.row.originalIndex; $bvModal.show('recommendationModal')"
              >Update</b-button>
            </span>
          </span>
          <!-- Column: Common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] || '-' }}
          </span>
        </template>

        <!-- pagination -->
        <template
          slot="pagination-bottom"
          slot-scope="props"
        >
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap ">
                Showing 1 to
              </span>
              <b-form-select
                v-model="pageLength"
                :options="['3','5','10']"
                class="mx-1"
                @input="(value)=>props.perPageChanged({currentPerPage:value})"
              />
              <span class="text-nowrap"> of {{ props.total }} entries </span>
            </div>
            <div>
              <b-pagination
                :per-page="pageLength"
                :total-rows="props.total"
                :value="1"
                align="right"
                class="mt-1 mb-0"
                first-number
                last-number
                next-class="next-item"
                prev-class="prev-item"
                @input="(value)=>props.pageChanged({currentPage:value})"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
      <b-modal
        id="recommendationModal"
        ok-only
        ok-title="Update"
        title="Recommendations"
        @ok="updateStatus"
      >
        <div v-if="rows[selectedRow]">
          <b-form-group
            label="Recommendations"
          >
            <b-form-input
              v-model="rows[selectedRow].programs_applicationscorestables[0].recommendations"
              placeholder="Your recommendation for startup"
            />
          </b-form-group>
          <b-form-group
            label="Verdict"
          >
            <v-select
              v-model="rows[selectedRow].programs_applicationscorestables[0].verdict"
              :options="['Excellent','Good','Satisfactory','Unsatisfactory','Incomplete']"
              placeholder="Select from list"
            />
          </b-form-group>
        </div>
      </b-modal>
    </b-card>
  </b-overlay>
</template>
<script>

import {
  BBadge, BButton, BFormGroup, BFormInput, BFormSelect, BPagination,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import gql from 'graphql-tag'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import vSelect from 'vue-select'
import { required, size } from '@core/utils/validations/validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BFormInput,
    BFormGroup,
    BButton,
    VueGoodTable,
    BPagination,
    BFormSelect,
    vSelect,
    BBadge,
  },
  data() {
    return {
      view: false,
      mutationLoading: false,
      update: false,
      searchTerm: '',
      selectedRow: 0,
      pageLength: 10,
      filter: {
        status: 'Current',
      },
      columns: [
        {
          label: 'Name',
          field: 'users_organizationtable.title',
        },
        {
          label: 'Recommendation',
          field: this.getFieldValue('recommendations'),
        },
        {
          label: 'Verdict',
          field: this.getFieldValue('verdict'),
        },
        {
          label: 'Action',
          field: 'action',
          sortable: false,
        },
      ],
      rows: [],
      is_internal: true,
      is_private: true,
      file_link: null,
      file: null,
      recommendationLevel: null,
      title: null,
      description: '',
      selectedOperation: null,
      required,
      size,
    }
  },
  methods: {
    getFieldValue(field) {
      return ({ programs_applicationscorestables }) => {
        if (!programs_applicationscorestables.length) {
          return '-'
        }
        return programs_applicationscorestables[0][field]
      }
    },
    updateStatus() {
      this.mutationLoading = true
      const mutationObject = {
        ...this.rows[this.selectedRow].programs_applicationscorestables[0],
      }

      // eslint-disable-next-line no-underscore-dangle
      this.$apollo.mutate(
        {
          mutation: gql`mutation UpsertVerdictAndRecommendation($verdict: String, $recommendation: String, $applicant_id_id: Int, $application_form_id: Int) {
            insert_programs_applicationscorestable_one(object: {verdict: $verdict, recommendations: $recommendation, applicant_id_id: $applicant_id_id, application_form_id: $application_form_id}, on_conflict: {constraint: programs_applicationscorestable_pkey, update_columns: [recommendations, verdict]}) {
              id
            }
          }`,
          variables: {
            verdict: mutationObject.verdict,
            recommendation: mutationObject.recommendations,
            applicant_id_id: this.rows[this.selectedRow].id,
            application_form_id: this.$route.params.fid,
            ...(mutationObject.id && { id: mutationObject.id }),
          },
          update: () => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Updated successfully',
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
            this.mutationLoading = false
            this.$apollo.queries.rows.refetch()
          },
        },
      )
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'There was an error',
              icon: 'XIcon',
              variant: 'danger',
            },
          })
          this.mutationLoading = false
        })
    },
  },
  apollo: {
    rows: {
      query() {
        return gql`
         query GetApplicants($fid: Int!) {
            programs_applicantstable(where: {current_application_form_id: {_eq: $fid}}) {
              id
              organization_id_id
              type
              users_organizationtable {
                title
              }
              programs_applicationscorestables {
                 id
                verdict
                recommendations
              }
            }
        }
       `
      },
      variables() {
        return {
          fid: this.$route.params.fid,
        }
      },
      update(data) {
        const rows = data.programs_applicantstable
        rows.forEach(row => {
          if (!row.programs_applicationscorestables.length) {
            row.programs_applicationscorestables.push({
              assignment_id: row.id,
              verdict: null,
              recommendations: null,
            })
          }
        })
        return rows
      },
    },
  },
}
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce.scss";
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
